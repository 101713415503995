import React from 'react';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {TranslationKeys} from 'utils/constants/routeConstants';

import AdditionalInformation from 'components/additional-information';
import ConnectLine from 'components/connect-line';
import ContactLine from 'components/contact-line';
import HelpInfoLine from 'components/helpInfo-line';

import './styles.scss';

type Props = {
  hideSeparator?: boolean;
}

export const Footer = ({hideSeparator}: Props): JSX.Element => {
  useGetTranslations({
    translationKey: TranslationKeys.Footer
  });

  return <footer className={'footer'}>
    <ContactLine hideSeparator={hideSeparator}/>
    <HelpInfoLine/>
    {/* <ConnectLine/>*/}
    <AdditionalInformation/>
  </footer>;
};
