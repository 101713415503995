import React, {FunctionComponent, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import ReadingPicture from 'assets/image/help-info-line/contactRead.svg';
import TalkPicture from 'assets/image/help-info-line/contactTalk.svg';
import WritingPicture from 'assets/image/help-info-line/contactWrite.svg';
import {feedbackTypes} from 'utils/constants/constants';
import FeedbackForms from 'components/feedback-forms/FeedbackForms';

import './styles.scss';

export const HelpInfoLine: FunctionComponent = () => {
  const [feedback, setFeedback] = useState('');

  function handleChangeFeedback(type: string) {
    return () => setFeedback(type);
  }

  return <div className="help-info">
    <div className="container container__wrapper">
      <div className="help-info-line">
        <div className="help-info-line__column help-info-line__column__side">
          <div className="help-info-line__title">
            <FormattedMessage
              id={'gritx.footer.help.report'}
              defaultMessage={'Report an Issue'}
            />
          </div>
          <p className="help-info-line__description">
            <button
              className="feedback-button"
              onClick={handleChangeFeedback(feedbackTypes.issue)}
            >
              <FormattedMessage
                id={'gritx.footer.help.seeIssues'}
                defaultMessage={'See issues on Gabi?'}
              />
            </button>
          </p>
          {/* <img src={TalkPicture} className="help-info-line__picture help-info-line__picture-talk" alt="" role="presentation"/>*/}
          {/* <div className="help-info-line__title">*/}
          {/*  <FormattedMessage*/}
          {/*    id={'gritx.footer.help.contentRequests'}*/}
          {/*    defaultMessage={'Content Requests'}*/}
          {/*  />*/}
          {/* </div>*/}
          {/* <p className="help-info-line__description">*/}
          {/*  <button*/}
          {/*    className="feedback-button"*/}
          {/*    onClick={handleChangeFeedback(feedbackTypes.newContent)}*/}
          {/*  >*/}
          {/*    <FormattedMessage*/}
          {/*      id={'gritx.footer.help.ideasNewContent'}*/}
          {/*      defaultMessage={'Have ideas on new content?'}*/}
          {/*    />*/}
          {/*  </button>*/}
          {/* </p>*/}
        </div>
        <div className="help-info-line__column help-info-line__column__center">
          {/* <div className="help-info-line__title">*/}
          {/*  <FormattedMessage*/}
          {/*    id={'gritx.footer.help.partnershipRequests'}*/}
          {/*    defaultMessage={'Partnership Requestse'}*/}
          {/*  />*/}
          {/* </div>*/}
          {/* <p className="help-info-line__description">*/}
          {/*  <button*/}
          {/*    className="feedback-button"*/}
          {/*    onClick={handleChangeFeedback(feedbackTypes.joinUCSF)}*/}
          {/*  >*/}
          {/*    <FormattedMessage*/}
          {/*      id={'gritx.footer.help.joinUCSF'}*/}
          {/*      defaultMessage={'Join UCSF'}*/}
          {/*    />*/}
          {/*  </button>*/}
          {/* </p>*/}
          <div className="help-info-line__title">
            <FormattedMessage
              id={'gritx.footer.help.shareStory'}
              defaultMessage={'Share story'}
            />
          </div>
          <p className="help-info-line__description">
            <button
              className="feedback-button"
              onClick={handleChangeFeedback(feedbackTypes.shareHistory)}
            >
              <FormattedMessage
                id={'gritx.footer.help.haveStory'}
                defaultMessage={'have Story?'}
              />
            </button>
          </p>
          {/* <img src={ReadingPicture} className="help-info-line__picture help-info-line__picture-read" alt="" role="presentation"/>*/}
        </div>
        <div className="help-info-line__column help-info-line__column__side">
          <div className="help-info-line__title">
            <FormattedMessage
              id={'gritx.footer.help.messageTeam'}
              defaultMessage={'Message Our Team'}
            />
          </div>
          <p className="help-info-line__description">
            <button
              className="feedback-button"
              onClick={handleChangeFeedback(feedbackTypes.chat)}
            >
              <FormattedMessage
                id={'gritx.footer.help.chat'}
                defaultMessage={'Chat with us'}
              />
            </button>
          </p>
          {/* <img src={WritingPicture} className="help-info-line__picture help-info-line__picture-write" alt="" role="presentation"/>*/}
          {/* <div className="help-info-line__title">*/}
          {/*  <FormattedMessage*/}
          {/*    id={'gritx.footer.help.joinNewsletter'}*/}
          {/*    defaultMessage={'Join newsletter'}*/}
          {/*  />*/}
          {/* </div>*/}
          {/* <p className="help-info-line__description">*/}
          {/*  <button*/}
          {/*    className="feedback-button"*/}
          {/*    onClick={handleChangeFeedback(feedbackTypes.newsletter)}*/}
          {/*  >*/}
          {/*    <FormattedMessage*/}
          {/*      id={'gritx.footer.help.SignUpNewsletter'}*/}
          {/*      defaultMessage={'Sign up for our newsletter'}*/}
          {/*    />*/}
          {/*  </button>*/}

          {/* </p>*/}
        </div>
      </div>
    </div>
    <FeedbackForms type={feedback} onClose={handleChangeFeedback('')}/>
  </div>;
};
